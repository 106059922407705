.page-container {
  width:100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  .container-item {
    width:calc(100% - 2em);
    margin-top: 1em;
  }

  .okay {
    border: green solid 2px;
  }

  .check {
    border: red solid 2px;
  }
}
body {
  margin: 0
}